<template>

    <iframe class="scaled-frame" src="https://b2c.pampadu.ru/index.html#1994e0eb-139c-4aee-8b35-0b68703c8422"
            style="width: 100%;
                border: none;
                min-height: 800px;
                min-width: 320px;
                overflow: scroll;"
            id=ppdwi></iframe>

</template>

<script>
export default {
  name: "Car"
}
</script>

<style scoped>


</style>
