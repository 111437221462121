<template>

  <iframe src="https://ipoteka.pampadu.ru/index.html#f8f870f2-39d3-4fc6-a80c-4fec69f3a2e2"
          style="width: 100%;
                border: none;
                min-height: 800px;
                overflow: scroll;"
          id=ppdwiIpoteka>

  </iframe>
</template>

<script>
export default {
  name: "Flat"
}
</script>

<style scoped>

</style>
