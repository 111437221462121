<template>

  <v-app variant="dark">
    <v-app-bar app density="compact">

      <v-app-bar-title>Страхование</v-app-bar-title>

      <v-spacer></v-spacer>
      <router-link class="routerLink ml-1" to="/car">
        <v-icon
            color="green darken-2"
        >
          mdi-car-hatchback
        </v-icon>
        АВТО
      </router-link>

      <router-link class="routerLink ml-1" to="/mortgage">
        <v-icon
            color="green darken-2"
        >
          mdi-home-city
        </v-icon>
        ИПОТЕКА
      </router-link>
    </v-app-bar>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <v-card
          class="flex"
          flat="yes"
          tile
          width="100%"
      >
        <v-card-text class="py-2 white--text text-center" align="center">
          {{ new Date().getFullYear() }} — <strong>v-docs.ru</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  created () {
    document.title = "v-docs.ru";
  },

  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://b2c.pampadu.ru/ppdw.js')
    document.head.appendChild(recaptchaScript)
  },

  data: () => ({
    selectedItem: 1,
    items: [
      {text: 'Real-Time', icon: 'mdi-clock'},
      {text: 'Audience', icon: 'mdi-account'},
      {text: 'Conversions', icon: 'mdi-flag'},
    ]
  }),
}
</script>

<style>

.routerLink {
  text-decoration: none;
  color: black;
}
</style>
