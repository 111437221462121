import { createRouter, createWebHistory } from 'vue-router'

import Car from "@/views/Car";
import Mortgage from "@/views/Mortgage";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Car
  },
  {
    path: '/car',
    name: 'car',
    component: Car
  },
  {
    path: '/mortgage',
    name: 'mortgage',
    component: Mortgage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
